import { Field, FormikProps } from "formik"
import { ItemInfoInForm } from "../../../types/items.type"
import { Button, Grid, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { LibraryOfThingsContractContext } from "../../../providers/LibraryOfThingsContractProvider";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ITEM_CATEGORIES from "../../../item-categories.config";
import { FormatLineHeight } from "mdi-material-ui";
import { assetDecimalRepresentationToInteger } from "../../../utilities/decimalsHandler/decimalsHandler";

const style = makeStyles({
  textField: {
    width: "100%",
    maxWidth: "300px",
    borderBottom: "1px",
    borderColor: "black",
    borderRadius: "10px",
  },
  container: {
    left: "1em",
    position: "relative",
    ["@media (min-width: 600px)"]: {
      marginTop: "2.5em",
    },
  },
  select: {
    width: "100%",
    maxWidth: "300px",
    position: "relative",
    background: "rgba(248, 248, 248, 1)",
    borderBottom: "1px",
    borderColor: "black",
    borderRadius: "10px",
  },
  radioGroup: {
    ["@media (min-width: 600px)"]: {
      flexDirection: "row",
    },
  },
  priceLabel: {
    position: "relative",
  },
  publishButton: {
    borderRadius: "15px",
    width: "200px",
    color: "white",
    background: "black",
    "&:hover": {
      background: "rgba(235, 176, 76, 1)",
      color: "black",
    },
    "&:disabled": {
      background: "rgba(129, 129, 129, 1)",
      color: "black",
    },
    "&:focus": {
      background: "rgba(218, 93, 62, 1)",
      color: "black",
    },
  },
  cancelButton: {
    borderRadius: "15px",
    width: "100px",
    borderColor: "none",
    background: "none",
    border: "none",
    ["@media (max-width: 600px)"]: {
      marginTop: "1em",
    },
  },
  buttonContainer: {
    ["@media (max-width: 600px)"]: {
      textAlign: "center",
    },
  },
});

export const UploadItemRightPanel = ({
  formik,
}: {
  formik: FormikProps<ItemInfoInForm>;
}) => {
  const classes = style();
  const { loading } = useContext(LibraryOfThingsContractContext);
  const { t } = useTranslation("LibOfThings");

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target != null && event.target.value != null) {
      const file = event.target.value;
      formik.setFieldValue("name", file);
    }
  };

  const handleChangeCategory = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (event.target != null && event.target.value != null) {
      formik.setFieldValue("category", event.target.value);
    }
  };

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={formik.values.name}
          id="standard-basic"
          label={`${t("insertItemName")}*`}
          onChange={handleChangeTextField}
          className={classes.textField}
          variant="outlined"
        />
        {formik.errors.name && formik.touched.name ? (
          <div>{formik.errors.name}</div>
        ) : null}
      </Grid>
      <Grid item container xs={12} alignItems="center" justifyContent="flex-start">
          <Select
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={classes.select}
            value={formik.values.category}
            onChange={handleChangeCategory}
          >
            {ITEM_CATEGORIES.map((category) => (
              <MenuItem value={category.value} key={category.value}>
                {`${category.text} (max prezzo: ${category.maxPrice > 0 ? category.maxPrice : 'nessuno'})`}
              </MenuItem>
            ))}
          </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formik.values.price}
          type="number"
          id="price"
          name="price"
          label={`Inserisci il prezzo giornaliero`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.textField}
          variant="outlined"
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
        {formik.errors.price && formik.touched.name ? (
          <div>{formik.errors.name}</div>
        ) : null}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "3em", marginTop: "1em" }}>
        <Grid container className={classes.buttonContainer}>
          <Grid item xs={12} sm={5}>
            <Button
              type="submit"
              variant="outlined"
              disabled={loading || Boolean(formik.errors.price)}
              className={classes.publishButton}
            >
              Pubblica
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};