import { Typography, makeStyles } from "@material-ui/core";
import config from "../../../../config";

const styles = makeStyles({
    tutorialPageTitle: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
    tutorialText: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
    },
});


export const MobileTab1 = () => {
    const style = styles();

    return (
        <>
            <Typography className={style.tutorialPageTitle}>Cos’è un {config.project.tokenSymbol}?</Typography>
            <Typography className={style.tutorialText}>
                È una “moneta” generata dallo scambio circolare degli oggetti. Ogni scambio o recupero oggetti genera “{config.project.tokenSymbol}s” alimentando le buone pratiche e le relazioni tra i membri
            </Typography>
            <ul style={{ textAlign: 'left' }} className={style.tutorialText}>
                <li><strong>15 {config.project.tokenSymbol}</strong> di benvenuto nella comunità</li>
                <li><strong>1 {config.project.tokenSymbol}</strong> per ogni oggetto messo a disposizione nella biblioteca delle cose</li>
                <li><strong>1 {config.project.tokenSymbol}</strong> ogni volta che restituisci un oggetto (integro)</li>
            </ul>
            <Typography className={style.tutorialText}>
                Ogni oggetto può essere preso in prestito per 1 {config.project.tokenSymbol} al giorno minimo. Puoi vedere i tuoi cosi all’interno del tuo portafoglio.
            </Typography>
        </>
    );
}