const ITEM_CATEGORIES = [
  {
    value: "ELETTRONICA",
    text: "Elettronica",
    maxPrice: 15,
  },
  {
    value: "GIOCHI",
    text: "Giochi",
    maxPrice: 3,
  },
  {
    value: "MATERIALE SCOLASTICO",
    text: "Materiale scolastico",
    maxPrice: 2,
  },
  {
    value: "ARTE E MUSICA",
    text: "Arte e musica",
    maxPrice: 7,
  },
  {
    value: "LIBRI",
    text: "Libri",
    maxPrice: 7,
  },
  {
    value: "BICICLETTE/TRASPORTI",
    text: "Biciclette / Trasporti",
    maxPrice: 12,
  },
  {
    value: "VARIE",
    text: "Varie",
    maxPrice: 0,
  },
] as const;

export default ITEM_CATEGORIES;
