import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { TransitionProps } from "@material-ui/core/transitions";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Slide, Typography, makeStyles } from "@material-ui/core";
import { rewardUserSlice } from "../../../store/slices/libOfThingsSlice";
import { getTokenFromMetadata } from "../../../api/coinAPI";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import CloseIcon from '@material-ui/icons/Close';
import config from "../../../config";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles({
    confirmButton: {
        textTransform: 'none',
        border: '1px solid rgba(0, 0, 0, 1)',
        borderRadius: '15px',
        '&:hover': {
            background: 'rgba(235, 176, 76, 1)',
            color: 'black'
        },
        '&:focus': {
            background: 'rgba(218, 93, 62, 1)',
            color: 'black'
        }
    },
    cancelButton: {
        color: 'black'
    },
    closeButton: {
        color: 'rgba(218, 93, 62, 1)',
        textTransform: 'none'
    },
    link: {
        color: 'rgba(218, 93, 62, 1)',
        '&:hover': {
            color: 'rgba(235, 176, 76, 1)'
        }
    }
})

export const CosoRewardDialog = () => {
    const userRewarded = useAppSelector(state => state.libOfThings.userRewarded);
    const loading = useAppSelector(state => state.libOfThings.loading);
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [manifestoAccepted, setManifestoAccepted] = useState(false);
    const classes = styles();
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        if (userRewarded)
            setOpen(false);
        else
            setOpen(true);
    }, [userRewarded]);

    const handleClickAccept = () => {
        setOpen(true);
        if (!manifestoAccepted) {
            toast.error(`Devi accettare il manifesto e le regole per entrare in ${config.project.name}`);
            return;
        }
        (async () => {
            const toastid = toast.loading('Caricamento...');
            const coso = await getTokenFromMetadata('', config.project.tokenSymbol);
            await dispatch(rewardUserSlice(coso.address));
            toast.dismiss(toastid);
            setStep(1);
        })();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeManifesto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setManifestoAccepted(event.target.checked);
    }

    const openWallet = () => {
        history.push('/user/wallet');
    }

    return (
      <Grid container>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{ textAlign: "center" }}
          >
            {step === 0 ? (
              <DialogTitle id="alert-dialog-slide-title">
                Manca poco per poter entrare in {config.project.name}
              </DialogTitle>
            ) : (
              <DialogTitle id="alert-dialog-slide-title">
                Ora partecipi alla comunità!
              </DialogTitle>
            )}
            <DialogContent>
              {step === 0 ? (
                <FormGroup row style={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={<Checkbox name="checkedA" />}
                    label={
                      <Typography variant="body2" style={{ cursor: "pointer" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Ho letto e accettato{" "}
                        </span>
                        <b>
                          <a
                            className={classes.link}
                            target="_blank"
                            rel="noreferrer"
                            href={
                              "https://drive.google.com/file/d/1YFg2QgJu7u-RuF5EOLsfUf_6hYmROT7K/view?usp=sharing"
                            }
                            hrefLang="ita"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Manifesto e Regolamento
                          </a>
                        </b>
                      </Typography> //TODO manifest
                    }
                    onChange={(e: any) => onChangeManifesto(e)}
                  />
                </FormGroup>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={openWallet}
                  >
                    Hai ricevuto <b>15 {config.project.tokenSymbol} </b>
                    di benvenuto!
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={openWallet}
                  >
                    Li trovi nel tuo portafoglio e puoi usarli per prendere in
                    prestito degli oggetti che trovi nella biblioteca delle cose
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {step === 0 ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginBottom: "0.5em",
                  }}
                >
                  <Grid item xs={12}>
                    <Button
                      onClick={handleClickAccept}
                      className={classes.confirmButton}
                      disabled={!manifestoAccepted || loading}
                    >
                      Partecipa a {config.project.name}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      onClick={openWallet}
                      className={classes.closeButton}
                    >
                      Il tuo portafoglio
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleClose}
                      className={classes.closeButton}
                    >
                      Chiudi
                    </Button>
                  </Grid>
                </Grid>
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    );
}

